@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'variables';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);

$custom-typography: mat.define-typography-config(
  $headline: mat.define-typography-level(24px, 32px, 700),
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Include custom typography
@include mat.typography-hierarchy($custom-typography);

// override mat card header text
.mat-card-header-text {
  width: 100%;
}
