@use '@angular/material' as mat;
@import 'theme';
@import 'variables';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;
}

body {
  background-image: url(assets/bg-waves-m.svg);
  background-repeat: repeat-x;
  background-size: 2500px;
  background-color: mat.get-color-from-palette($accent, 50);
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
  text-align: center;
}
h3 {
  font-size: 18px;
  font-weight: 300;
  text-align: center;

  &.sub-title {
    padding-left: 24px;
    padding-right: 24px;
  }
}
h4 {
  text-align: center;
}
li,
p {
  font-size: 14px;
}
a,
a:visited {
  color: mat.get-color-from-palette($primary, '200-contrast');
}

header,
section {
  max-width: $medium-desktop;
  color: rgb(60, 60, 60);
  padding: 0 32px;
  width: 100%;

  @media (min-width: $mobile) {
    padding: 0 64px;
  }
}
header {
  color: unset;
}

.full-width {
  width: 100%;
}
.white-bg-color {
  background-color: white;
}
.underline {
  text-decoration: underline;
}

@media (min-width: $mobile) {
  body {
    background-size: 1800px;
  }
  h3 {
    &.sub-title {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
@media (min-width: $tablet) {
  body {
    background-size: 2000px;
  }
  h3 {
    &.sub-title {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}
@media (min-width: $tablet) {
  body {
    background-size: 2000px;
  }
}

@media (min-width: $medium-desktop) {
  body {
    background-image: url(assets/bg-waves-l.svg);
    background-size: 2500px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 20px;
    &.sub-title {
      padding-left: 96px;
      padding-right: 96px;
    }
  }
}

@media (min-width: $large-desktop) {
  body {
    background-size: 2800px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 44px;
  }
  h3 {
    font-size: 20px;
  }
}
