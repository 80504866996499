@import '~@angular/material/theming';

$primary: mat-palette($mat-deep-purple);
$accent: mat-palette($mat-amber, A400, A200, A700);
$warn: mat-palette($mat-red);
$success: mat-palette($mat-green);

$host-padding: 16px;

$mobile: 480px;
$tablet: 768px;
$small-desktop: 992px;
$medium-desktop: 1200px;
$large-desktop: 1440px;
$xl-desktop: 1920px;
